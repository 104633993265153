export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
    resource: 'Auth',
    action: 'view',
  },
  {
    title: 'Profile',
    route: 'pages-profile',
    icon: 'UserIcon',
    resource: 'Auth',
    action: 'view',
  },
  {
    title: 'Settings',
    route: 'pages-settings',
    icon: 'SettingsIcon',
    resource: 'Settings',
    action: 'manage',
  },
  {
    title: 'Our Users',
    icon: 'UsersIcon',
    route: 'apps-users-list',
    resource: 'User',
    action: 'manage',
  },
  {
    title: 'Manufacturers',
    route: 'pages-manufacturers',
    icon: 'LayersIcon',
    resource: 'Manufacturer',
    action: 'manage',
  },
  {
    title: 'Agents',
    route: 'pages-agents',
    icon: 'UserCheckIcon',
    resource: 'Agent',
    action: 'manage',
  },
  {
    title: 'Brands',
    route: 'pages-brands',
    icon: 'SmileIcon',
    resource: 'Brand',
    action: 'manage',
  },
  {
    title: 'Collections',
    route: 'pages-collections',
    icon: 'FolderIcon',
    resource: 'Collection',
    action: 'manage',
  },
  {
    title: 'Styles',
    route: 'apps-styles-list',
    icon: 'PackageIcon',
    resource: 'Style',
    action: 'manage',
  },
  {
    title: 'Materials',
    route: 'apps-materials-list',
    icon: 'ScissorsIcon',
    resource: 'Material',
    action: 'manage',
  },
  {
    title: 'Sample Requests',
    route: 'apps-sample-requests-list',
    icon: 'BoxIcon',
    resource: 'SampleRequest',
    action: 'manage',
  },
  {
    title: 'Supply chain',
    route: 'apps-suppliers-list',
    icon: 'LinkIcon',
    resource: 'Supplier',
    action: 'manage',
  },
]
