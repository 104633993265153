<template>
  <b-nav-item-dropdown
    right
    class="notifications-dropdown"
    menu-class="notifications-menu"
    @show="dropdownShown"
  >
    <template #button-content>
      <div
        v-if="newMessage"
        class="d-flex"
      >
        <div style="margin: 5px; width: 8px; height: 8px; border-radius: 50%; background-color: #EA5455;" />
        <feather-icon
          size="16"
          icon="BellIcon"
          class="mr-50 text-danger"
        />
      </div>
      <feather-icon
        v-else
        size="16"
        icon="BellIcon"
        class="mr-50"
      />
    </template>
    <vue-perfect-scrollbar
      v-if="notificationsCount"
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
    >
      <b-dropdown-item
        v-for="notification in notifications"
        :key="notification.id"
        link-class="d-flex"
        @click="setNotificationRead(notification.id)"
      >
        <div>
          <b-link
            :to="notificationUrl(notification)"
            class="d-flex align-items-center"
          >
            <div>
              <feather-icon
                v-if="!notification.is_read"
                size="6"
                icon="CircleIcon"
                class="mr-50 text-secondary"
              />
              <feather-icon
                v-else
                size="4"
                icon="CircleIcon"
                class="mr-50 invisible"
              />
            </div>
            <div>
              <b-avatar
                v-if="['sample-not-sent', 'sample-not-received'].includes(notification.action)"
                size="32"
                variant="light-primary"
              ><feather-icon icon="CalendarIcon" />
              </b-avatar>
              <b-avatar
                v-else
                size="32"
                :src="notification.user_photo"
                :text="avatarText(notification.user_full_name)"
                variant="light-primary"
              />
            </div>
            <div class="px-1 font-weight-light notification-message">
              {{ notification.message }}
            </div>
            <div>
              <b-img
                v-if="notification.cover_image"
                rounded
                height="32"
                :src="notification.cover_image"
              />
            </div>
          </b-link>
        </div>
      </b-dropdown-item>
      <div
        v-if="notifications.length < notificationsCount"
        class="d-flex justify-content-center"
      >
        <b-button
          variant="primary"
          size="sm"
          :disabled="isLoading"
          @click.stop="onSeeMore"
        >
          See more
        </b-button>
      </div>
    </vue-perfect-scrollbar>
    <div
      v-else
      class="text-center text-primary notification-message"
      size="sm"
    >
      Without notifications
    </div>
  </b-nav-item-dropdown>
</template>
<script>
import axios from '@axios'
import {
  computed, onMounted, onUnmounted, ref,
} from '@vue/composition-api'
import {
  BAvatar, BDropdownItem, BImg, BLink, BNavItemDropdown, BButton,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import useJwt from '@/auth/jwt/useJwt'
import { avatarText } from '@core/utils/filter'
import store from '@/store'

export default {
  components: {
    BAvatar,
    BButton,
    BDropdownItem,
    BImg,
    BLink,
    BNavItemDropdown,
    VuePerfectScrollbar,
  },
  setup() {
    const newMessage = ref(false)
    const notifications = ref([])
    const notificationsCount = ref(0)
    const isLoading = ref(false)
    const userData = ref(JSON.parse(localStorage.getItem('userData')))
    const lastNotificationsCheck = ref(new Date(userData.value.lastNotificationsCheck))

    const token = useJwt.getRefreshToken()
    let wsURL = `ws://127.0.0.1:8000/ws/notifications?token=${token}`
    if (process.env.NODE_ENV === 'production') {
      wsURL = `wss://api-smarthub.avevalley.ai/ws/notifications?token=${token}`
    } else if (process.env.NODE_ENV === 'staging') {
      wsURL = `wss://api-smarthub.consipere.com/ws/notifications?token=${token}`
    }

    const NOTIFICATIONS_STORE_MODULE_NAME = 'notifications'

    const notificationsStore = {
      namespaced: true,
      state: {},
      getters: {},
      mutations: {},
      actions: {
        fetchNotifications(ctx, queryParams) {
          return new Promise((resolve, reject) => {
            axios
              .get('/api/v1/notifications/', { params: queryParams })
              .then(response => resolve(response))
              .catch(error => reject(error))
          })
        },
        setNotificationRead(ctx, id) {
          return new Promise((resolve, reject) => {
            axios
              .patch(`/api/v1/notifications/${id}/set_read/`)
              .then(response => resolve(response))
              .catch(error => reject(error))
          })
        },
        updateNotificationsCheck() {
          return new Promise((resolve, reject) => {
            axios
              .patch('/api/v1/users/me/', {
                last_notifications_check: new Date().toISOString(),
              })
              .then(response => resolve(response))
              .catch(error => reject(error))
          })
        },
      },
    }

    // Register module
    if (!store.hasModule(NOTIFICATIONS_STORE_MODULE_NAME)) {
      store.registerModule(NOTIFICATIONS_STORE_MODULE_NAME, notificationsStore)
    }
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(NOTIFICATIONS_STORE_MODULE_NAME)) {
        store.unregisterModule(NOTIFICATIONS_STORE_MODULE_NAME)
      }
    })

    const notifSocket = new WebSocket(wsURL)

    notifSocket.onmessage = event => {
      const data = JSON.parse(event.data)
      notifications.value = [data, ...notifications.value]
      newMessage.value = true
      notificationsCount.value += 1
    }

    const dropdownShown = () => {
      store.dispatch('notifications/updateNotificationsCheck').then(response => {
        newMessage.value = false
        lastNotificationsCheck.value = new Date(response.data.last_notifications_check)
        userData.value = {
          ...userData.value,
          lastNotificationsCheck: response.data.last_notifications_check,
        }
        localStorage.setItem('userData', JSON.stringify(userData.value))
      })
    }

    const perfectScrollbarSettings = {
      maxScrollbarLength: 100,
      wheelPropagation: false,
    }

    const notificationUrl = computed(() => notification => {
      if (notification.action === 'style-status' && notification.style_id) {
        return {
          name: 'apps-styles-edit',
          params: { id: notification.style_id },
        }
      }
      if (['sample-request-status', 'sample-commented',
        'sample-not-sent', 'sample-not-received'].includes(notification.action)
              && notification.sample_request_id) {
        return {
          name: 'apps-sample-requests-edit',
          params: { id: notification.sample_request_id },
        }
      }
      if (notification.action === 'material-commented' && notification.style_id) {
        return {
          name: 'apps-styles-edit',
          params: { id: notification.style_id },
          hash: '#1',
        }
      }
      if (notification.action === 'fabrics-commented' && notification.style_id) {
        return {
          name: 'apps-styles-edit',
          params: { id: notification.style_id },
          hash: '#1',
        }
      }
      if (notification.action === 'trims-commented' && notification.style_id) {
        return {
          name: 'apps-styles-edit',
          params: { id: notification.style_id },
          hash: '#2',
        }
      }
      if (notification.action === 'packaging-commented' && notification.style_id) {
        return {
          name: 'apps-styles-edit',
          params: { id: notification.style_id },
          hash: '#5',
        }
      }
      if (notification.action === 'embellishment-commented' && notification.style_id) {
        return {
          name: 'apps-styles-edit',
          params: { id: notification.style_id },
          hash: '#2',
        }
      }
      if (notification.action === 'style-sizes-and-measurements-commented' && notification.style_id) {
        return {
          name: 'apps-styles-edit',
          params: { id: notification.style_id },
          hash: '#3',
        }
      }
      if (notification.action === 'style-prices-commented' && notification.style_id) {
        return {
          name: 'apps-styles-edit',
          params: { id: notification.style_id },
          hash: '#6',
        }
      }
      if (notification.action === 'supplier-status' && notification.supplier_id) {
        return {
          name: 'apps-suppliers-edit',
          params: { id: notification.supplier_id },
        }
      }
      return {}
    })

    const fetchNotifications = () => {
      if (isLoading.value) {
        return
      }
      if (notifications.value.length) {
        if (notifications.value.length >= notificationsCount.value) {
          return
        }
      }
      isLoading.value = true
      const page = (notifications.value.length / 10) + 1
      store.dispatch(
        'notifications/fetchNotifications',
        { page },
      )
        .then(response => {
          notificationsCount.value = response.data.count
          notifications.value.push(
            ...response.data.results.map(notification => {
              const data = notification.extra_data
              data.id = notification.id
              data.is_read = notification.is_read
              data.action = notification.action
              data.create_date = new Date(notification.create_date)
              return data
            }),
          )
          if (notificationsCount.value) {
            const lastNotification = notifications.value[0]
            if (lastNotificationsCheck.value < lastNotification.create_date) {
              newMessage.value = true
            }
          }
          isLoading.value = false
        })
        .catch(error => {
          if (!error.response || error.response.status === 404) {
            isLoading.value = false
          }
        })
    }

    const setNotificationRead = id => {
      store.dispatch('notifications/setNotificationRead', id)
        .then(() => {
          const notificationIndex = notifications.value.findIndex(notification => notification.id === id)
          if (notificationIndex !== -1) {
            const notificationFound = notifications.value[notificationIndex]
            notifications.value.splice(notificationIndex, 1, { ...notificationFound, is_read: true })
          }
        })
    }

    const onSeeMore = () => {
      if (!isLoading.value) {
        fetchNotifications()
      }
    }

    onMounted(fetchNotifications)

    return {
      avatarText,
      perfectScrollbarSettings,

      newMessage,
      notifications,
      notificationsCount,
      isLoading,

      dropdownShown,
      onSeeMore,
      setNotificationRead,
      notificationUrl,
    }
  },
}
</script>
<style scoped>
.notification-message {
  font-size: 0.857rem;
  white-space: normal;
  width: 400px;
}
</style>
